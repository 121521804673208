<template>
  <v-row align="center" no-gutters style="min-width: 10%">
    <v-col justify="center" cols="4">
      <v-img :src="imgLogo" :width="imgWidth" />
    </v-col>
    <v-col justify="baseline">
      <v-toolbar-title class="hidden-md-and-down">
        <router-link
          to="/"
          custom
          v-slot="{ navigate }"
          style="cursor: pointer"
          class="text-h5 font-weight-bold"
        >
          <span @click="navigate" @keypress.enter="navigate" role="link">
            {{ appTitle }}
          </span>
        </router-link>
      </v-toolbar-title>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DefaultLogo",
  data() {
    return {
      appTitle: "ИСУНГ",
      imgLogo: require("@/assets/img/logo.png"),
      imgWidth: 40,
    };
  },
};
</script>
